define('ember-cp-validations/validators/inline', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _base.default.extend({
    /**
     * Override the validator's `validate` method with the one that was
     * passed in via the options.
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions: function buildOptions() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      (true && !(options && typeof options.validate === 'function') && Ember.assert('[validator:inline] You must pass in a validate function', options && typeof options.validate === 'function'));


      var opts = Ember.assign({}, options);

      this.validate = opts.validate;
      delete opts.validate;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return this._super.apply(this, [opts].concat(_toConsumableArray(args)));
    }
  });
});