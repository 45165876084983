define("ember-cp-validations/decorators/has-validations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasValidations = hasValidations;
  /**
   * Decorator that allows you to mixin validations when using es6 style model classes
   *
   * Usage:
   *
   * import { hasValidations } from 'ember-cp-validations';
   *
   * @hasValidations(Validations)
   * export default class YourModel extends Model {
   *    @attr('string') name
   * };
   *
   *
   * @param validations
   * @returns {decorator}
   */

  function hasValidations(validations) {
    return function decorator(target) {
      target.prototype.reopen(validations);
    };
  }
});