define('ember-bootstrap-cp-validations/components/bs-form', ['exports', 'ember-bootstrap/components/bs-form'], function (exports, _bsForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bsForm.default.extend({
    hasValidator: Ember.computed.notEmpty('model.validate'),

    validate: function validate(model) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var m = model;

        if (model instanceof Ember.ObjectProxy) {
          m = model.get('content');
        }

        m.validate().then(function () {
          return model.get('validations.isTruelyValid') ? resolve() : reject();
        }, reject);
      });
    }
  });
});